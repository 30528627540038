import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './MapPathsMenu.scss';
import {ReactComponent as CheckedBox} from '../../asstes/svg/checkbox.svg';
import {ReactComponent as UncheckedBox} from '../../asstes/svg/checkbox-empty.svg';

const MapPaths = [
  'Round',
  'Walk',
  'Bike',
  // 'Horse',
  // 'Kayak',
];

const MapNameMapper = {
  'Bike': 'Cykelrute',
  'Horse': 'Riderute',
  'Kayak': 'Kajak',
  'Walk': 'Gårute',
  'Round': 'Naturpark Åmosen Rundt',
};

const InverseMapNameMapper = {
  'Cykelrute': 'Bike',
  'Riderute': 'Horse',
  'Kajak': 'Kayak',
  'Gårute': 'Walk',
  'Naturpark Åmosen Rundt': 'Round',
};

export default class MapPathsMenu extends React.PureComponent {

  getPathId = (pathName) => {
    return 'path-' + pathName.toLowerCase();
  }

  changePathsVisibility = (e) => {
    const menuElement = e.target.parentNode;
    const clickedLayer = InverseMapNameMapper[menuElement.firstElementChild.firstChild.textContent];

    if (!menuElement.classList.contains('menu-option')) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    const visibility = this.props.map.getLayoutProperty(this.getPathId(clickedLayer), 'visibility');

    // Toggle layer visibility by changing the layout object's visibility property.
    if (visibility === 'visible') {
      this.props.map.setLayoutProperty(this.getPathId(clickedLayer), 'visibility', 'none');
      menuElement.classList.remove('active');
    } else {
      menuElement.classList.add('active');
      this.props.map.setLayoutProperty(
        this.getPathId(clickedLayer),
        'visibility',
        'visible'
      );
    }
  };

  render = () => (
    <div className="MapPathsMenu">
      {MapPaths.map((path, key) =>
        (<a href="#" className={'active menu-option ' + path} key={key + 100} onClick={this.changePathsVisibility}>
          <div className="MapPathsMenu__text">{MapNameMapper[path]}</div>
          <CheckedBox/>
          <UncheckedBox />
        </a>))
      }
    </div>
  );
}

MapPathsMenu.propTypes = {
  map: PropTypes.object
};