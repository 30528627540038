import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

// Styles.
import './MapMarkerPopup.scss';

const MapMarkerPopup = ({
  item,
}) => {
  return (
    <div className="MapMarkerPopup">

      {item.category &&
      <h3 className="MapMarkerPopup__category">{ ReactHtmlParser(item.category) }</h3>
      }

      {item.title &&
      <h2 className="MapMarkerPopup__title">
        { ReactHtmlParser(item.title) }
      </h2>
      }

      <br/>

      {item.image &&
      <img className="MapMarkerPopup__image" alt={item.title} src={item.image}/>
      }

      {item.address &&
      <p className="MapMarkerPopup__regular"><b>Adresse:</b> {item.address}</p>
      }

      {item.phone &&
      <p className="MapMarkerPopup__regular"><b>Telefon:</b> {item.phone}</p>
      }

      {item.mail &&
      <p className="MapMarkerPopup__regular"><b>Mail:</b> {item.mail}</p>
      }

      {item.web &&
      <p className="MapMarkerPopup__regular"><b>Web:</b> <a href={item.web}>{item.web}</a></p>
      }

      {item.data &&
      <p className="MapMarkerPopup__regular"><a href={item.data}>{item.data}</a></p>
      }

      {item.description &&
      <p className="MapMarkerPopup__regular">
        { ReactHtmlParser (item.description) }
      </p>
      }

      {item.source &&
      <div className="MapMarkerPopup__source">
        <div>
          <p>{ ReactHtmlParser (item.source) }</p>
        </div>
        {
          item.userName &&
          <div>
            <p className="MapMarkerPopup__user">- { ReactHtmlParser (item.userName) }</p>
          </div>
        }
      </div>
      }

    </div>
  );
};

MapMarkerPopup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    image: PropTypes.string,
    category: PropTypes.string,
    subCategory: PropTypes.string,
    address: PropTypes.string,
    phone: PropTypes.string,
    mail: PropTypes.string,
    web: PropTypes.string,
    userName: PropTypes.string,
    description: PropTypes.string,
  }),
};

export default MapMarkerPopup;
