import React from 'react';
import mapboxgl from 'mapbox-gl';
import ReactDOMServer from 'react-dom/server';

export const addPathsSource = map => {
  map.on('load', () => {
    addRoutesForTransportation(map, 'Walk', '#000000');
    addRoutesForTransportation(map, 'Bike', '#1A544D');
    addRoutesForTransportation(map, 'Horse', '#cc3300');
    addRoutesForTransportation(map, 'Kayak', '#B44403');
    addRoutesForTransportation(map, 'Round', '#EF7D2C');
  });
};

const getPathName = (transport) => {
  return 'path-' + transport.toLowerCase();
};

const addRoutesForTransportation = (map, transport, pathColor) => {
  map.addSource(transport, {
    type: 'geojson',
    data: process.env.PUBLIC_URL + '/paths/' + transport + '.geojson'
  });

  map.addLayer({
    'id': getPathName(transport),
    'type': 'line',
    'source': transport,
    'layout': {
      'visibility': 'visible',
      'line-join': 'round',
      'line-cap': 'round'
    },
    'paint': {
      'line-color': pathColor,
      'line-width': 2
    }
  });

  map.on('mouseenter', 'path-' + transport.toLowerCase(), () => {
    map.getCanvas().style.cursor = 'pointer';
    map.setPaintProperty(getPathName(transport), 'line-width', 5);
  });

  map.on('mouseleave', 'path-' + transport.toLowerCase(), () => {
    map.getCanvas().style.cursor = '';
    map.setPaintProperty(getPathName(transport), 'line-width', 2);
  });

  map.on('click', 'path-' + transport.toLowerCase(), (e) => {
    const featuresOnClickedTile = map.queryRenderedFeatures(e.point);
    let popupText = null;

    for (const feature of featuresOnClickedTile) {
      if (Object.keys(feature.properties).length === 0) {
        return;
      }

      if ('Signaturfo' in feature.properties) {
        popupText = feature.properties.Signaturfo;
      }
    }

    const center = map.getCenter();
    new mapboxgl.Popup()
      .setHTML("<div class=\"MapMarkerPopup\"><p class=\"MapMarkerPopup__path\">" + popupText + "</p></div>")
      .setLngLat(e.lngLat)
      .addTo(map);
  });
};
