import React from 'react';

// Styles.
import './MapMarkerCluster.scss';

const MapMarkerCluster = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="MapMarkerCluster">
      <div className="MapMarker__circle">
        {props.count}
      </div>
    </div>
  );
});

export default MapMarkerCluster;
