export const saveToLocalStorage = (data, params) => {
    let date = new Date();
    date.setTime(date.getTime() + (60*60*1000));

    localStorage.setItem('mapItems' + params, JSON.stringify(data));
    localStorage.setItem('mapItemsExpr' + params, date.toDateString());
};

export const getStorageItems = (name, params) => {
    return JSON.parse(localStorage.getItem(name + params));
};

export const checkStorageTime = (params) => {
    let currentTime = new Date();
    let expireDate = new Date(localStorage.getItem('mapItemsExpr' + params));

    if (expireDate < currentTime) {
        localStorage.removeItem('mapItems' + params);
    }
};
