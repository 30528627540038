import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './InputFileLabelFilled.scss';

// Assets.
import { ReactComponent as TimesLightIcon } from '../../../asstes/svg/times-light.svg';

const InputFileLabelFilled = ({
  name,
  onClickCallback,
}) => (
  <div className="InputFileLabelFilled">

    <div className="InputFileLabelFilled__text">
      {name}
    </div>

    <div onClick={onClickCallback} className="InputFileLabelFilled__icon">
      <TimesLightIcon/>
    </div>

  </div>
);

InputFileLabelFilled.propTypes = {
  name: PropTypes.string,
  onClickCallback: PropTypes.func,
};

export default InputFileLabelFilled;
