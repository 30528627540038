import storage from 'redux-persist/lib/storage';
import { createMigrate } from 'redux-persist';

// Reconciler.
import reduxPersistStateReconciler from './reduxPersistStateReconciler';

// Constants.
import { REDUX_PERSIST_KEY } from '../../../constants/Settings';

// Transforms.
import placesTransform from './transform/placesTransform';

const migrations = {
  1: (state) => (state),
  2: () => ({}),
};

const reduxPersistConfig = {
  version: 2,
  key: REDUX_PERSIST_KEY,
  storage,
  stateReconciler: reduxPersistStateReconciler,
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [
    placesTransform,
  ],
  whitelist: [
    'places',
  ],
};

export default reduxPersistConfig;
