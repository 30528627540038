import React from 'react';

// Components.
import Map from '../components/map/Map';

const App = () => (
  <div className="App">
    <Map/>
  </div>
);

export default App;
