import axios from 'axios';
import {AIR_TABLE_SERVICES_FIELDS, AIR_TABLE_URL, AIR_TABLE_AUTH, AIR_TABLE_URL_PREFIX} from '../../constants/Settings';
import {getCategoryList} from './categoriesApi';

export const getServicesList = callback => {
  let transformedData = [];

  const processAnotherPage = (categories, offset, callback) => {
    axios.get(AIR_TABLE_URL + AIR_TABLE_URL_PREFIX + '&offset=' + offset + AIR_TABLE_SERVICES_FIELDS, AIR_TABLE_AUTH)
      .then(response => {
        processAndPaginate(response, callback, categories);
      });
  };

  const fetchServices = categories => {
    axios.get(AIR_TABLE_URL + AIR_TABLE_URL_PREFIX + AIR_TABLE_SERVICES_FIELDS, AIR_TABLE_AUTH)
      .then(response => {
        processAndPaginate(response, callback, categories);
      })
      .catch(function (error) {
        console.log('error', error);
      });
  };

  const processAndPaginate = (response, callback, categories) => {
    processRecords(response.data.records, categories);

    if (response.data.offset) {
      processAnotherPage(categories, response.data.offset, callback);
    } else {
      callback(transformedData);
    }
  };

  const processRecords = (records, categories) => {
    for (const item of records) {
      if (!('Y-koor' in item.fields && 'X-koor' in item.fields)) {
        continue;
      }

      if (!('Vises på kort' in item.fields)) {
        continue;
      }

      transformedData.push({
        title: item.fields['Virksomheds Navn'],
        category: 'Katagori' in item.fields ? item.fields['Katagori'].map(Id => categories[Id].name).join(', ') : '',
        address: 'Adresse' in item.fields ? item.fields['Adresse'] : '',
        phone: 'Telefon ' in item.fields ? item.fields['Telefon '] : '',
        mail: 'mail' in item.fields ? item.fields['mail'] : '',
        web: 'web' in item.fields ? item.fields['web'] : '',
        description: 'Beskrivelse af virskomhed' in item.fields ? item.fields['Beskrivelse af virskomhed'] : '',
        data: 'evt. billede af virksomhed/produkt/oplevelse' in item.fields
          ? item.fields['evt. billede af virksomhed/produkt/oplevelse']
          : '',
        type: 'Katagori' in item.fields ? item.fields['Katagori'].map(Id => categories[Id].type) : '',
        icon: 'Katagori' in item.fields ? item.fields['Katagori'].map(Id => categories[Id].icon) : '',
        location: {
          lat: item.fields['Y-koor'].toString(),
          lng: item.fields['X-koor'].toString(),
        }
      });
    }
  };

  getCategoryList(fetchServices);
};
