import React from 'react';

// Styles.
import './MapMarkerAdd.scss';

// Assets.ma
import { ReactComponent as LogoIcon } from '../../asstes/svg/marker-logo.svg';

const MapMarkerAdd = React.forwardRef((props, ref) => (
  <div ref={ref} className="MapMarkerAdd">
    <div className="MapMarkerAdd__remove">
      x
    </div>
    <div className="MapMarkerAdd__circle--outer"/>
    <div className="MapMarkerAdd__circle--outer-2"/>
    <div className="MapMarkerAdd__circle--inner">
      <LogoIcon/>
    </div>
  </div>
));

export default MapMarkerAdd;
