import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './InputFileLabelEmpty.scss';

// Assets.
import { ReactComponent as CameraRegularIcon } from '../../../asstes/svg/camera-regular.svg';

const InputFileLabelEmpty = ({
  name,
}) => (
  <div className="InputFileLabelEmpty">

    <div className="InputFileLabelEmpty__icon">
      <CameraRegularIcon/>
    </div>

    <div className="InputFileLabelEmpty__text">
      {name}
    </div>

  </div>
);

InputFileLabelEmpty.propTypes = {
  name: PropTypes.string,
};

export default InputFileLabelEmpty;
