import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './MapOverlay.scss';

// Assets.
import { ReactComponent as LogoIcon } from '../../asstes/svg/marker-logo.svg';
import { ReactComponent as InformationIcon } from '../../asstes/svg/information-outline.svg';

const MapOverlay = ({
  onClickFormCallback,
  onClickInfoCallback
}) => (
  <div className="MapOverlay">
    <button onClick={onClickFormCallback} className="MapOverlay__button">
      <LogoIcon/>
    </button>
    <button onClick={onClickInfoCallback} className="MapOverlay__button mt-2">
      <InformationIcon/>
    </button>
  </div>
);

MapOverlay.propTypes = {
  onClickFormCallback: PropTypes.func.isRequired,
  onClickInfoCallback: PropTypes.func.isRequired
};

export default MapOverlay;
