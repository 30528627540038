import thunkMiddleware from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import { createStore, compose, applyMiddleware } from 'redux';

// Reducers.
import getReducers from '../../reducers/getReducers';

// Middleware.
import asyncMiddleware from './middleware/asyncMiddleware';

// Utilities.
import reduxPersistConfig from './persist/reduxPersistConfig';

// Constants.
import { ENV_DEVELOPMENT } from '../../constants/Settings';

const reduxConfigureStore = (initialState = {}) => {

  // Order is important.
  const middleware = [
    thunkMiddleware,
    asyncMiddleware,
  ];

  // Using redux dev tools only on dev env.
  const isDev = process.env.NODE_ENV === ENV_DEVELOPMENT;

  // Add support for Redux dev tools.
  const composeEnhancers = isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

  return createStore(
    persistReducer(reduxPersistConfig, getReducers()),
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );
};

export default reduxConfigureStore;
