import React from 'react';
import PropTypes from 'prop-types';

// Styles.
import './MapMarkerFormAddSuccess.scss';

const MapMarkerFormAddSuccess = ({
  onClickCallback,
}) => (
  <div className="MapMarkerFormAddSuccess">

    <p className="MapMarkerFormAddSuccess__text MapMarkerFormAddSuccess__text--bold">
      Tak for din historie til borgerkortet.
    </p>

    <p className="MapMarkerFormAddSuccess__text">
      Når vi har indsamlet tilstrækkelig med historier om naturen, de særegne steder og værdierne i Naturpark Åmosen vil
      dine historier blive offentlig tilgængelig. Historierne vil findes på hjemmesiden til gavn for andre gæster og borgere.
      Alle historier skal dog først godkendes. Det er meningen, at der fra hjemmesiden kan generes foldere, kort og andet
      materiale som gæster i naturparken frit kan printe og tage med hjemmefra. Historierne kommer selvfølgelig også på
      naturparkens hjemmeside. På Roskilde Universitet og på vegne af projekt ”Nye Veje i Naturpark Åmosen” er vi også
      glade for din historie, og vi arbejder videre på vores Geopedia over Åmosen.
    </p>

    <button
      type="button"
      onClick={onClickCallback}
      className="MapMarkerFormAddSuccess__button"
    >
      LUK
    </button>

  </div>
);

MapMarkerFormAddSuccess.propTypes = {
  onClickCallback: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    userName: PropTypes.string,
  }),
};

export default MapMarkerFormAddSuccess;
