import axios from 'axios';
import {AIR_TABLE_AUTH, AIR_TABLE_URL} from '../../constants/Settings';

export const getCategoryList = callback => {
   axios.get(AIR_TABLE_URL + '/Kategori?view=Grid%20view', AIR_TABLE_AUTH)
    .then(response => {
      let data = {};

      for (const item of response.data.records) {
        data[item.id] = {
          name: item.fields['Underkategori'],
          type: item.fields['Over kategori'],
          icon: item.fields['Join Feature Ikon']
        };
      }

      callback(data);
    })
    .catch(function (error) {
      console.log('error', error);
    });
};
