import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldMetaPropTypes, fieldInputPropTypes } from 'redux-form/lib/propTypes';
import { map } from 'lodash';

// Styles.
import './InputSelect.scss';

// Assets.
import { ReactComponent as ArrowIcon } from '../../asstes/svg/chevron-down-regular.svg';
import ReactHtmlParser from "react-html-parser";

const InputSelect = ({
  id,
  label,
  name,
  meta,
  input,
  options,
  placeholder,
}) => {

  const showError = meta.touched && !meta.active && meta.error;
  const inputClassName = classNames('InputSelect', {
    'InputSelect--error': showError,
  });

  return (
    <div className={inputClassName}>

      {label &&
      <label
        htmlFor={input.name}
        className="InputText__label"
      >
        {label}
      </label>
      }

      <div className="InputSelect__arrow">
        <ArrowIcon/>
      </div>

      <select
        {...input}
        id={id}
        name={name}
        className="InputSelect__input"
      >

        {placeholder &&
        <option disabled={true} value="">
          {placeholder}
        </option>
        }

        {map(options, (item, index) => (
          <option key={index} value={item.value}>
            { ReactHtmlParser (item.label) }
          </option>
        ))}

      </select>

      {showError &&
      <span className="InputSelect__error">
        {meta.error}
      </span>
      }

    </div>
  );
};

InputSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default React.memo(InputSelect);
