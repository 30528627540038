import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form/lib/propTypes';

// Styles.
import './InputText.scss';

const InputText = ({
  id,
  type,
  meta,
  input,
  label,
  placeholder,
  autoCorrect,
  autoComplete,
}) => {

  const showError = meta.touched && !meta.active && !!meta.error;
  const inputClassName = classNames('InputText', {
    'InputText--error': showError,
  });

  return (
    <div className={inputClassName}>

      {label &&
      <label
        htmlFor={input.name}
        className="InputText__label"
      >
        {label}
      </label>
      }

      <input
        {...input}
        id={id}
        type={type}
        className="InputText__input"
        placeholder={placeholder}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
      />

      {showError &&
      <span className="InputText__error">
        {meta.error}
      </span>
      }

    </div>
  );
};

InputText.defaultProps = {
  autoCorrect: 'off',
  autoComplete: 'off',
};

InputText.propTypes = {
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  autoCorrect: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default React.memo(InputText);
