import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Styles.
import './Modal.scss';

class Modal extends React.PureComponent {

  getWrapperClassName = () => (
    classNames('Modal', {
      'Modal--active': this.props.isActive,
    })
  );

  onClickBody = (event) => {
    event.stopPropagation();
  };

  render() {
    const wrapperClassName = this.getWrapperClassName();
    return (
      <div className={wrapperClassName}>
        <div onClick={this.props.onClickClose} className="Modal__content">
          <div onClick={this.onClickBody} className="Modal__body">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  isActive: PropTypes.bool,
  onClickClose: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
