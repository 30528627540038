import React from 'react';

// Styles.
import './MapMarker.scss';

// Assets.
import { ReactComponent as Marker } from '../../asstes/svg/marker-logo.svg';
import { ReactComponent as Camp } from '../../asstes/svg/camp.svg';
import { ReactComponent as Fire } from '../../asstes/svg/fire.svg';
import { ReactComponent as Parking } from '../../asstes/svg/parking.svg';
import { ReactComponent as House } from '../../asstes/svg/shelter.svg';
import { ReactComponent as Bed } from '../../asstes/svg/bed.svg';
import { ReactComponent as Playground } from '../../asstes/svg/services-icons/Aktiviteter og oplevelser.svg';
import { ReactComponent as Facilities } from '../../asstes/svg/services-icons/Faciliteter og Infrastruktur.svg';
import { ReactComponent as Overnight } from '../../asstes/svg/services-icons/Overnatning.svg';
import { ReactComponent as Shops } from '../../asstes/svg/services-icons/Salg og Indkøb.svg';
import { ReactComponent as FoodServices } from '../../asstes/svg/services-icons/Spisesteder og Forplejning.svg';

import { ReactComponent as Icon01A } from '../../asstes/svg/sub-category-icons/01A.svg';
import { ReactComponent as Icon01B } from '../../asstes/svg/sub-category-icons/01B.svg';
import { ReactComponent as Icon02A } from '../../asstes/svg/sub-category-icons/02A.svg';
import { ReactComponent as Icon02B } from '../../asstes/svg/sub-category-icons/02B.svg';
import { ReactComponent as Icon03A } from '../../asstes/svg/sub-category-icons/03A.svg';
import { ReactComponent as Icon03B } from '../../asstes/svg/sub-category-icons/03B.svg';
import { ReactComponent as Icon03C } from '../../asstes/svg/sub-category-icons/03C.svg';
import { ReactComponent as Icon04 } from '../../asstes/svg/sub-category-icons/04.svg';
import { ReactComponent as Icon04A } from '../../asstes/svg/sub-category-icons/04A.svg';
import { ReactComponent as Icon04B } from '../../asstes/svg/sub-category-icons/04B.svg';
import { ReactComponent as Icon04C } from '../../asstes/svg/sub-category-icons/04C.svg';
import { ReactComponent as Icon04D } from '../../asstes/svg/sub-category-icons/04D.svg';
import { ReactComponent as Icon04E } from '../../asstes/svg/sub-category-icons/04E.svg';
import { ReactComponent as Icon04F } from '../../asstes/svg/sub-category-icons/04F.svg';
import { ReactComponent as Icon04G } from '../../asstes/svg/sub-category-icons/04G.svg';
import { ReactComponent as Icon04H } from '../../asstes/svg/sub-category-icons/04H.svg';
import { ReactComponent as Icon04I } from '../../asstes/svg/sub-category-icons/04I.svg';
import { ReactComponent as Icon05A } from '../../asstes/svg/sub-category-icons/05A.svg';
import { ReactComponent as Icon05B } from '../../asstes/svg/sub-category-icons/05B.svg';
import { ReactComponent as Icon05C } from '../../asstes/svg/sub-category-icons/05C.svg';
import { ReactComponent as Icon05D } from '../../asstes/svg/sub-category-icons/05D.svg';
import { ReactComponent as Icon05E } from '../../asstes/svg/sub-category-icons/05E.svg';
import { ReactComponent as Icon05F } from '../../asstes/svg/sub-category-icons/05F.svg';
import { ReactComponent as Icon05G } from '../../asstes/svg/sub-category-icons/05G.svg';
import { ReactComponent as Icon05H } from '../../asstes/svg/sub-category-icons/05H.svg';
import { ReactComponent as Icon05I } from '../../asstes/svg/sub-category-icons/05I.svg';
import { ReactComponent as Icon05J } from '../../asstes/svg/sub-category-icons/05J.svg';
import { ReactComponent as Icon05K } from '../../asstes/svg/sub-category-icons/05K.svg';
import { ReactComponent as Icon05L } from '../../asstes/svg/sub-category-icons/05L.svg';
import { ReactComponent as Icon05M } from '../../asstes/svg/sub-category-icons/05M.svg';
import { ReactComponent as Icon06 } from '../../asstes/svg/sub-category-icons/06.svg';
import { ReactComponent as Icon06A } from '../../asstes/svg/sub-category-icons/06A.svg';
import { ReactComponent as Icon06B } from '../../asstes/svg/sub-category-icons/06B.svg';
import { ReactComponent as Icon06C } from '../../asstes/svg/sub-category-icons/06C.svg';
import { ReactComponent as Icon06D } from '../../asstes/svg/sub-category-icons/06D.svg';
import { ReactComponent as Icon06E } from '../../asstes/svg/sub-category-icons/06E.svg';

const components = {
  Feedback: Marker,
  Teltplads: Camp,
  Bålplads: Fire,
  Parkering: Parking,
  Shelter: House,
  Overnatning: Bed,
};

const setAllLogos = () => {
  components['Aktiviteter og Oplevelser'] = Playground;
  components['Faciliteter og Infrastruktur'] = Facilities;
  components['Overnatning'] = Overnight;
  components['Salg og Indkøb'] = Shops;
  components['Spisesteder og Forplejning'] = FoodServices;
  components['01A'] = Icon01A;
  components['01B'] = Icon01B;
  components['02A'] = Icon02A;
  components['02B'] = Icon02B;
  components['03C'] = Icon03C;
  components['03A'] = Icon03A;
  components['03B'] = Icon03B;
  components['04'] = Icon04;
  components['04A'] = Icon04A;
  components['04B'] = Icon04B;
  components['04C'] = Icon04C;
  components['04D'] = Icon04D;
  components['04E'] = Icon04E;
  components['04F'] = Icon04F;
  components['04G'] = Icon04G;
  components['04H'] = Icon04H;
  components['04I'] = Icon04I;
  components['05A'] = Icon05A;
  components['05B'] = Icon05B;
  components['05C'] = Icon05C;
  components['05D'] = Icon05D;
  components['05E'] = Icon05E;
  components['05F'] = Icon05F;
  components['05G'] = Icon05G;
  components['05H'] = Icon05H;
  components['05I'] = Icon05I;
  components['05J'] = Icon05J;
  components['05K'] = Icon05K;
  components['05L'] = Icon05L;
  components['05M'] = Icon05M;
  components['06'] = Icon06;
  components['06A'] = Icon06A;
  components['06B'] = Icon06B;
  components['06C'] = Icon06C;
  components['06D'] = Icon06D;
  components['06E'] = Icon06E;
};

const marshalIcon = (item) => {
  if (item && Array.isArray(item.icon) && item.icon[0] && components[item.icon[0]]) {
    return components[item.icon[0]];
  }

  if (item && Array.isArray(item.type)) {
    return item && components[item.type[0]] ? components[item.type[0]] : components['Feedback'];
  } else {
    return item && components[item.type] ? components[item.type] : components['Feedback'];
  }
};

const MapMarker = React.forwardRef((props, ref) => {
  setAllLogos();

  let MarkerType = components['Feedback'];

  MarkerType = marshalIcon(props.item);

  return (
    <div ref={ref} className="MapMarker">
      <div className="MapMarker__circle">
        <MarkerType />
      </div>
    </div>
  );
});

export default MapMarker;
