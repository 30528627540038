import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Arrow} from '../../asstes/svg/arrow.svg';

class MapInstruction extends React.PureComponent {

  handleClick = () => {
    this.props.onClickCallback([[this.props.callbackValue, false]]);
  }

  render = () => (
    <div
      className={this.props.showInstructions
        ?'Map__instructions'
        :'Map__instructions-inactive'}
    >
      <div className={'Instructions' + this.props.instructionClass}>
        <div className="Instructions__Bubble">
          {this.props.instructions}
          {this.props.showCloseButton
            ? <div className="Instructions__Close" onClick={this.handleClick}>x</div>
            : ''
          }

        </div>
        <div className="Instructions__Arrow"><Arrow /></div>
      </div>
    </div>
  );
}

MapInstruction.propTypes = {
  onClickCallback: PropTypes.func.isRequired,
  callbackValue: PropTypes.number.isRequired,
  instructions: PropTypes.string.isRequired,
  showInstructions: PropTypes.bool.isRequired,
  showCloseButton: PropTypes.bool.isRequired,
  instructionClass: PropTypes.string.isRequired,
  seen: PropTypes.bool.isRequired,
};

export default MapInstruction;
