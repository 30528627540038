import { handleActions } from 'redux-actions';

// Utilities.
import placesAddHandler from './handlers/placesAddHandler';

// Constants.
import { PLACES_ADD } from '../../constants/Places';

export const initialState = {
  items: {},
};

const placesReducer = handleActions({
    [PLACES_ADD]: (state, action) => ({
      ...state,
      ...placesAddHandler(state, action),
    }),
  },
  initialState,
);

export default placesReducer;
