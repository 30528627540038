import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldMetaPropTypes, fieldInputPropTypes } from 'redux-form/lib/propTypes';

// Styles.
import './InputFile.scss';


import InputFileLabelFilled from './InputFileLabelFilled';
import InputFileLabelEmpty from './InputFileLabelEmpty';

class InputFile extends React.Component {

  onChange = (event) => {
    this.props.input.onChange(event.target.files[0]);
  };

  onClickClear = (event) => {
    event.preventDefault();

    // Because same file cannot be selected twice, and browser does not allow controlled component on file input.
    this.inputRef.value = '';
    this.props.input.onChange(null);
  };

  setInputRef = (ref) => {
    this.inputRef = ref;
  };

  render = () => {

    const showError = this.props.meta.touched && !this.props.meta.active && this.props.meta.error;
    const inputClassName = classNames('InputFile', {
      'InputFile--error': showError,
    });

    return (
      <div className={inputClassName}>

        {this.props.label &&
        <label
          htmlFor={this.props.input.name}
          className="InputFile__label"
        >

          {this.props.input.value ? (
            <InputFileLabelFilled
              name={this.props.input.value.name}
              onClickCallback={this.onClickClear}
            />
          ) : (
            <InputFileLabelEmpty name={this.props.label}/>
          )}

        </label>
        }

        <input
          type="file"
          accept=".jpg, .png, .jpeg"
          ref={this.setInputRef}
          onChange={this.onChange}
          id={this.props.input.name}
          className="InputFile__input"
        />

        {showError &&
        <span className="InputText__error">
          {this.props.meta.error}
        </span>
        }

      </div>
    );
  };
}

InputFile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default InputFile;
