/**
 * Places ass handler.
 *
 * @param {Object} state
 *  State object.
 * @param {Object} action
 *  Action object.
 *
 * @return {Object}
 *  Updated state.
 */
const locationSetUserDataHandler = (state, { payload }) => ({
  items: {
    ...state.items,
    [payload.id]: payload,
  },
});

export default locationSetUserDataHandler;
