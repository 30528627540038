export const PLACES_ADD = 'PLACES_ADD';
export const ADDABLE_CATEGORY = 'feedback';
export const PATHS = 'paths';
export const ADDABLE_CATEGORY_TYPE = 'Feedback';
export const SERVICES = 'air-table';

export const LAYERCHOOSER_ORDER = {
  'Borgernes Fortællinger': 0,
  'Kirke- og godslandskabet': 1,
  'Historien - den historiske mose': 2,
  'Arkæologi, oldtid & vikinger - den forhistoriske mose': 3,
  'Arkæologi, oldtid &amp; vikinger - den forhistoriske mose': 3,
  'Sagn & myter - den mystiske mose': 4,
  'Sagn &amp; myter - den mystiske mose': 4,
  'Naturværdier - den prægtige mose': 5,
  'Kulturværdier - den kunstneriske mose': 6
};
