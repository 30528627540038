import { createTransform } from 'redux-persist';

const placesTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => outboundState,
  {
    whitelist: [
      'places',
    ],
  },
);

export default placesTransform;
