import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Reducers.
import placesReducer from './places/placesReducer';

/**
 * Combined reducers.
 */
const getReducers = () => (
  combineReducers({
    form: formReducer,
    places: placesReducer,
  })
);

export default getReducers;
