import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fieldInputPropTypes, fieldMetaPropTypes } from 'redux-form/lib/propTypes';

// Styles.
import './InputTextArea.scss';

const InputTextArea = ({
  id,
  meta,
  input,
  label,
  placeholder,
}) => {

  const showError = meta.touched && !meta.active && !!meta.error;
  const inputClassName = classNames('InputTextArea', {
    'InputTextArea--error': showError,
  });

  return (
    <div className={inputClassName}>

      {label &&
      <label
        htmlFor={input.name}
        className="InputText__label"
      >
        {label}
      </label>
      }

      <textarea
        {...input}
        id={id}
        rows="4"
        className="InputTextArea__input"
        placeholder={placeholder}
      />

      {showError &&
      <div className="InputTextArea__error--wrapper">
        <span className="InputTextArea__error">
          {meta.error}
        </span>
      </div>
      }

    </div>
  );
};


InputTextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
};

export default React.memo(InputTextArea);
