export const getParamValue = (paramName) => {
  let url = window.location.search.substring(1); //get rid of "?" in querystring
  let qArray = url.split('&'); //get key-value pairs
  for (let i = 0; i < qArray.length; i++)
  {
    let pArr = qArray[i].split('='); //split key and value
    if (pArr[0] === paramName)
      return checkIfValueIsArray(pArr[1]); //return value
  }
};

const checkIfValueIsArray = (parameters) => {
  if (parameters.indexOf(',') > -1) {
    let values = parameters.split(',');
    return values.filter(function (el) {
      return el !== '';
    });
  } else {
    return [parameters];
  }
};
