// Constants.
import {
  REGEX_ONLY_NUMBERS,
  REGEX_FIRST_SYMBOL_PLUS,
} from '../../../constants/RegExp';

/**
 * Normalize phone number input value.
 *
 * @param {string} value
 *  Input value.
 *
 * @return {string}
 *  Normalized phone number.
 */
const normalizePhoneNumber = (value) => {
  if (!value) {
    return '';
  }

  const onlyNumbers = value.replace(REGEX_ONLY_NUMBERS, '');
  const containPlusSymbol = value.match(REGEX_FIRST_SYMBOL_PLUS);
  return `${containPlusSymbol ? '+' : ''}${onlyNumbers}`;
};

export default normalizePhoneNumber;
