import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import mapboxgl from 'mapbox-gl';
import { saveToLocalStorage, getStorageItems, checkStorageTime } from '../../utilities/cache/localStorage';
import { getServicesList } from '../../utilities/air-table/servicesApi';
import { getParamValue } from '../../utilities/http/urlParams';
import { connect } from 'react-redux';
import { compose } from 'redux';
import map from 'lodash/map';
import axios from 'axios';
import { addPathsSource } from '../../utilities/geojson-sources/paths';
import { orderCategoryList } from '../../utilities/air-table/orderer';

// Styles.
import './Map.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import './MapMarker.scss';
import './MapInstruction.scss';

// Components.
import Modal from '../modal/Modal';
import MapMarker from './MapMarker';
import MapOverlay from './MapOverlay';
import MapMarkerAdd from './MapMarkerAdd';
import MapMarkerForm from './MapMarkerForm';
import MapMarkerPopup from './MapMarkerPopup';
import MapInstruction from './MapInstruction';
import MapPathMenu from './MapPathsMenu';

// Constants.
import { FORM_NAME_MAP_MARKER } from '../../constants/Form';
import {
  MAPBOX_STYLE,
  MAPBOX_TOKEN,
  DEFAULT_LOCATION,
  MAPBOX_POPUP_OFFSETS,
  API_DOMAIN,
} from '../../constants/Settings';
import { INFO_TEXT_HTML } from '../../constants/InfoText';
import {ADDABLE_CATEGORY, ADDABLE_CATEGORY_TYPE, LAYERCHOOSER_ORDER, PATHS, SERVICES} from '../../constants/Places';

// Assets
import { ReactComponent as NordeaIcon } from '../../asstes/svg/nordea-fon-logo.svg';
import { ReactComponent as NpaIcon } from '../../asstes/svg/npa-logo.svg';
import { ReactComponent as RucIcon } from '../../asstes/svg/ruc-logo.svg';
import { ReactComponent as CheckedBox } from '../../asstes/svg/checkbox.svg';
import { ReactComponent as UncheckedBox } from '../../asstes/svg/checkbox-empty.svg';
import ReactHtmlParser from 'react-html-parser';
import MapMarkerCluster from './MapMarkerCluster';

class Map extends React.PureComponent {

  constructor(props) {
    super(props);

    mapboxgl.accessToken = MAPBOX_TOKEN;
    this.declusterificationDistance = 0.0001;
    this.state = {
      showModal: false,
      instructionsSeen: [
        true,
        true,
        true,
        true
      ],
      showInstructions: [
        true,
        false,
        false,
        false
      ],
      location: undefined,
      usedTypes: [],
      usedCategories: [],
      markers: {},
    };
  }

  state = {};

  componentDidMount() {
    this.initMap();
  }

  initMap = () => {
    this.map = new mapboxgl.Map({
      zoom: 10.5,
      style: MAPBOX_STYLE,
      container: this.mapRef,
      center: [DEFAULT_LOCATION.lng, DEFAULT_LOCATION.lat],
    });

    if (this.inIframe()) {
      this.map.scrollZoom.disable();
      this.enableScrollZoomOnCtrl();
    }

    this.map.addControl(new mapboxgl.NavigationControl());

    this.getMapData();

    this.checkIfInstructionsSeen();

    this.enableResizingOnZoom();

    this.onClickPopInfo();

    this.scaleMarkers();
  };

  inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  checkIfInstructionsSeen = () => {
    let storedInstructions = JSON.parse(localStorage.getItem('seens'));
    let instructionSeens = storedInstructions ? storedInstructions : [false, false, false, false];
    this.setState({
      instructionsSeen: instructionSeens
    });
  };

  enableResizingOnZoom = () => {
    let self = this;
    this.map.on('render', function() {
      self.scaleMarkers();
    });
  };

  scaleMarkers = () => {
    const scale = this.map.getZoom() > 11 ? 11 : this.map.getZoom();
    let markers = document.querySelectorAll('.mapboxgl-marker');
    map(markers, (item) => {
      if (item.children[0].children[0]) {
        item.children[0].children[0].style.width = Math.pow(1.5, scale) / 3 + 'px';
        item.children[0].children[0].style.height = Math.pow(1.5, scale) / 3 + 'px';
      }
    });
  }

  clusterifyMarkers = (markersHtml = null) => {
    if (!markersHtml) {
      markersHtml = document.querySelectorAll('.mapboxgl-marker-custom');
    }

    const multipleMarkers = this.findPlacesWhereMultipleMarkers(markersHtml);

    const clusters = document.querySelectorAll('.mapboxgl-marker-cluster');

    this.removeClustersWhereTheyAreNotNeededAnymore(markersHtml, multipleMarkers, clusters);
    this.addClustersWhereMultpleMarkers(markersHtml, multipleMarkers);
  };

  findPlacesWhereMultipleMarkers = (markersHtml) => {
    const multipleMarkers = {};

    for (const marker of markersHtml) {
      // If cluster exists on display none marker it still counts it unless marker category checkbox is unchecked
      if (marker.style.display === 'none' && !this.clusterExistsOnMarker(marker)) {
        continue;
      }

      if (this.shouldDecreaseClusterCount(marker)) {
        this.decreaseClusterCount(marker);
        continue;
      }

      if (!this.getMarkerCategoryCheckboxValue(marker)) {
        continue;
      }

      const coords = this.getElementCoordinates(marker);

      // Counting markers in the same place
      multipleMarkers[coords] = (multipleMarkers[coords] || 0) + 1;
    }

    return multipleMarkers;
  };

  shouldDecreaseClusterCount = (element) => {
    return 'decreaseCluster' in element.dataset && element.dataset.decreaseCluster === 'true';
  };

  decreaseClusterCount = (marker) => {
    const markerCoords = this.getElementCoordinates(marker);
    const cluster = this.getClusterByCoordinates(markerCoords);

    const currentCount = cluster.firstElementChild.firstElementChild.innerHTML;
    cluster.firstElementChild.firstElementChild.innerHTML = String(currentCount - 1);

    marker.dataset.decreaseCluster = 'false';
  }

  getElementCoordinates = (element) => {
    return [element.dataset.lng, element.dataset.lat];
  }

  getClusterByCoordinates = (markerCoords) => {
    const clusters = document.querySelectorAll('.mapboxgl-marker-cluster');

    for (const cluster of clusters) {
      const clusterCoords = this.getElementCoordinates(cluster);

      if (this.isCoordinatesMatching(markerCoords, clusterCoords)) {
        return cluster;
      }
    }

    return false;
  }

  getMarkersByCoordinates = (lng, lat) => {
    return document.querySelectorAll(
      '.mapboxgl-marker-custom[data-lng="' + lng + '"][data-lat="' + lat + '"]'
    );
  }

  addClustersWhereMultpleMarkers = (markersHtml, multipleMarkers) => {
    for (const element of markersHtml) {
      if (element.style.display === 'none' && !this.clusterExistsOnMarker(element)) {
        continue;
      }

      const mapMarkerCoords = [element.dataset.lng, element.dataset.lat];

      if (multipleMarkers[mapMarkerCoords] === 1 || multipleMarkers[mapMarkerCoords] === undefined) {
        continue;
      }

      element.style.display = 'none';

      let clusterExists = false;
      const clusters = document.querySelectorAll('.mapboxgl-marker-cluster');
      for (const cluster of clusters) {
        const clusterCoords = [cluster.dataset.lng, cluster.dataset.lat];

        if (this.isCoordinatesMatching(clusterCoords, mapMarkerCoords)) {
          clusterExists = true;

          this.updateClusterNumber(cluster, multipleMarkers[mapMarkerCoords]);
        }
      }

      if (!clusterExists) {
        this.addStaticClusterMarker(multipleMarkers[mapMarkerCoords], mapMarkerCoords);
      }
    }
  };

  updateClusterNumber = (cluster, multipleMarkersCount) => {
    const currentCount = cluster.firstElementChild.firstElementChild.innerHTML;

    if (multipleMarkersCount === currentCount) {
      return;
    }

    cluster.firstElementChild.firstElementChild.innerHTML = String(multipleMarkersCount);
  }

  removeClustersWhereTheyAreNotNeededAnymore = (markersHtml, multipleMarkers, clusters) => {
    for (const cluster of clusters) {
      const clusterCoords = [cluster.dataset.lng, cluster.dataset.lat];

      if (multipleMarkers[clusterCoords] === 1 || multipleMarkers[clusterCoords] === undefined) {
        this.makeMarkerVisibleIfClusterDoesNotExistAnymore(markersHtml, clusterCoords);
        cluster.remove();
      }
    }
  };

  makeMarkerVisibleIfClusterDoesNotExistAnymore = (markersHtml, clusterCoords) => {
    for (const marker of markersHtml) {
      const mapMarkerCoords = [marker.dataset.lng, marker.dataset.lat];

      if (!this.isCoordinatesMatching(mapMarkerCoords, clusterCoords) && !this.declusteredByClick(marker)) {
        continue;
      }

      if (this.getMarkerCategoryCheckboxValue(marker)) {
        marker.dataset.decreaseCluster = 'false';
        marker.style.display = 'block';
      }
    }
  };

  getMarkerCategoryCheckboxValue = (marker) => {
    return document.getElementById(marker.classList[1]).classList.contains('active');
  }

  clusterExistsOnMarker = (marker) => {
    const clusters = document.querySelectorAll('.mapboxgl-marker-cluster');

    for (const cluster of clusters) {
      const clusterCoords = [cluster.dataset.lng, cluster.dataset.lat];
      const mapMarkerCoords = [marker.dataset.lng, marker.dataset.lat];

      if (this.isCoordinatesMatching(clusterCoords, mapMarkerCoords)) {
        return true;
      }
    }

    return false;
  };

  declusteredByClick = (marker) => {
    if (marker.hasAttribute('data-declusterified')) {
      return true;
    }

    return false;
  };

  isCoordinatesMatching = (coords1, coords2) => {
    if (coords1[0] !== coords2[0]) {
      return false;
    }

    if (coords1[1] !== coords2[1]) {
      return false;
    }

    return true;
  };

  enableScrollZoomOnCtrl = () => {
    this.map.on('wheel', event => {
      if (event.originalEvent.ctrlKey) {
        // Prevent chrome/firefox default behavior
        event.originalEvent.preventDefault();
        if (!this.map.scrollZoom._enabled) this.map.scrollZoom.enable();
      } else {
        if (this.map.scrollZoom._enabled) this.map.scrollZoom.disable();
      }
    });
  };

  getMapData = () => {
    let paramArr = getParamValue('category');
    let paramsString = paramArr ? paramArr.join('-') : '';
    checkStorageTime(paramsString);
    let data = getStorageItems('mapItems', paramsString);

    if (paramArr && paramArr.includes('air-table')) {
      this.addServicesOnMap();
    }

    if (paramArr && paramArr.includes(PATHS)) {
      addPathsSource(this.map);
    }

    if (data == null) {
      this.dataFetchingFromApi(paramArr);
    } else {
      data.forEach(item => this.addStaticMarker(item));
    }

    return data;
  };

  addServicesOnMap = () => {
    getServicesList((result) => {
      for (const item of result) {
        let addedTypes = [];

        if (!item.type) {
          this.addStaticMarker(item);
          continue;
        }

        item.type.forEach((type, index) => {
          if (addedTypes.includes(type)) {
            return;
          }

          addedTypes.push(type);
          item.type = [type];
          item.icon[0] = [item.icon[index]];
          this.addStaticMarker(item);
        });
      }

      this.clusterifyMarkers();
    });
  }

  dataFetchingFromApi = (parameters) => {
    let urlParams = parameters != null ? '?category=' + parameters.join() : '';
    axios.get(API_DOMAIN + '/get' + urlParams)
      .then(response => {
        response.data.items.forEach(item => this.addStaticMarker(item));
        saveToLocalStorage(response.data.items, parameters ? parameters.join('-') : '');
      })
      .catch(function (error) {
        console.log('error', error);
      });
  };

  addStaticMarker = (item) => {
    const html = (
      <MapMarker item={item}/>
    );

    let div = document.createElement('div');
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(html);
    div.classList.add('MapMarker');
    div.dataset.lng = item.location.lng;
    div.dataset.lat = item.location.lat;

    if (item.type && Array.isArray(item.type)) {
      div = this.addServiceClassFromType(item, div);
    }

    if (item.type === ADDABLE_CATEGORY_TYPE && item.category) {
      div = this.addCategoryClass(item, div);
    }

    const marker = new mapboxgl.Marker({
      element: div,
    }).setLngLat([item.location.lng, item.location.lat])
      .addTo(this.map)
      .setPopup(this.getMarkerPopup(item));

    this.scaleMarkers();

    this.setState({
      markers: {
        ...this.state.markers,
        ...{[this.formatCoordinatesAndType(item.location.lng, item.location.lat, div.classList[1])]: marker}
      }
    });

    marker.getElement().classList.add('mapboxgl-marker-custom');
  };

  formatCoordinatesAndType = (lng, lat, type) => {
    return lng + ',' + lat + '-' + type;
  }

  addStaticClusterMarker = (count, coords) => {
    const html = (
      <MapMarkerCluster count={count}/>
    );

    let div = document.createElement('div');
    div.innerHTML = ReactDOMServer.renderToStaticMarkup(html);
    div.dataset.lng = coords[0];
    div.dataset.lat = coords[1];

    const cluster = new mapboxgl.Marker({
      element: div,
    }).setLngLat(coords)
      .addTo(this.map);

    const element = cluster.getElement();
    element.classList.add('mapboxgl-marker-cluster');
    element.addEventListener('click', this.removeMarkerCluster);

    this.scaleMarkers();
  }

  removeMarkerCluster = (event) => {
    const clusterElement = event.target.parentElement.parentElement;

    const markers = this.getMarkersByCoordinates(clusterElement.dataset.lng, clusterElement.dataset.lat);
    // this.state.markers[marker].setLngLat([lng, lat + 0.00001]);
    markers.forEach((marker, index) => {
      if (index === 0) {
        return;
      }

      const mapboxglMarkerElement = this.state.markers[
        this.formatCoordinatesAndType(marker.dataset.lng, marker.dataset.lat, marker.classList[1])
      ];

      marker.dataset.lat = String(parseFloat(marker.dataset.lat) + this.declusterificationDistance * index);
      marker.dataset.declusterified = String(this.declusterificationDistance * index);

      mapboxglMarkerElement.setLngLat([marker.dataset.lng, marker.dataset.lat]);
    });

    this.map.flyTo({
      center: [clusterElement.dataset.lng, clusterElement.dataset.lat],
      essential: true,
      zoom: 18
    });

    this.clusterifyMarkers();
  }

  addServiceClassFromType = (item, div) => {
    item.type.forEach(type => {
      div.classList.add(type.replace(/ /g, '_'));

      if (!this.state.usedTypes.some(usedType => usedType.name === type)) {
        this.setState({
          usedTypes: this.state.usedTypes.concat({
            name: type,
            class: type.replace(/ /g, '_')
          })
        });
      }
    });

    return div;
  }

  addCategoryClass = (item, div) => {
    div.classList.add(item.category.replace(/ /g, '_'));

    if (this.getCategoryIndex(item.category) === null) {
      return div;
    }

    if (!this.state.usedCategories.some(usedCategorie => usedCategorie && usedCategorie.name === item.category)) {
      const stateCategories = [...this.state.usedCategories];

      stateCategories[this.getCategoryIndex(item.category)] = {
        name: item.category,
        class: item.category.replace(/ /g, '_')
      };

      this.setState({
        usedCategories: stateCategories,
      });
    }


    return div;
  }

  getCategoryIndex = (categoryName) => {
    if (isNaN(LAYERCHOOSER_ORDER[categoryName])) {
      return null;
    }

    return LAYERCHOOSER_ORDER[categoryName];
  };

  getMarkerPopup = (item) => {
    const html = ReactDOMServer.renderToStaticMarkup(
      <MapMarkerPopup item={item}/>,
    );

    return new mapboxgl.Popup({
      className: 'MapMarkerPopup__wrapper',
      offset: MAPBOX_POPUP_OFFSETS,
    }).setHTML(html)
      .setMaxWidth('350px');
  };

  onClickAdd = () => {
    this.movableMarker && this.movableMarker.remove();
    this.changeInstructionVisibility([[0, false], [1, true]]);

    // Mapbox does not accept react elements so we have to pass ref.
    // We could create element on the fly, but reusing existing element is much cleaner.
    const center = this.map.getCenter();
    this.movableMarker = new mapboxgl.Marker({
      draggable: true,
      element: this.markerAddRef.cloneNode(true),
    }).setLngLat([center.lng, center.lat])
      .addTo(this.map);

    // Add on click listener after we have reference to marker.
    this.movableMarker.getElement().onclick = this.onClickPlace(this.movableMarker);
    this.movableMarker.getElement().children[0].onclick = this.onClickRemove(this.movableMarker);
  };

  onClickPlace = (marker) => () => {
    this.changeInstructionVisibility([[1, false], [2, true], [3, true]]);
    this.setState({
      showModal: true,
      location: marker.getLngLat(),
    });
  };

  onClickRemove = (marker) => (ev) => {
    marker.remove();
    this.changeInstructionVisibility([[1, false]]);
    ev.stopPropagation();
  };

  onClickPopInfo = () => {
    let paramArr = getParamValue('category');

    if (paramArr && paramArr.includes('feedback')) {
      const center = this.map.getCenter();
      new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat([center.lng, center.lat])
        .setHTML(INFO_TEXT_HTML)
        .setMaxWidth('600px')
        .addTo(this.map)
        .addClassName('info-popup');
    }
  };

  onSubmit = (item) => {
    this.changeInstructionVisibility([[2, false], [3, false]]);
    this.movableMarker && this.movableMarker.remove();
    this.addStaticMarker(item);
  };

  onClickChangeVisibility = item => e => {
    let listItem = document.getElementById(item.class);
    let elements = document.getElementsByClassName(item.class);

    if (listItem.classList.contains('active')) {
      listItem.classList.remove('active');
    } else {
      listItem.classList.add('active');
    }

    for (const element of elements) {
      if (listItem.classList.contains('active')) {
        if (this.clusterExistsOnMarker(element)) {
          element.dataset.decreaseCluster = 'false';
        } else {
          element.style.display = 'block';
        }
      } else {
        if (this.clusterExistsOnMarker(element)) {
          element.dataset.decreaseCluster = 'true';
        } else {
          element.style.display = 'none';
        }
      }
    }

    this.clusterifyMarkers();
  };

  onClickCloseModal = () => {
    this.setState({
      showModal: false,
    });
  };

  setMapRef = (ref) => {
    this.mapRef = ref;
  };

  setMarkerAddRef = (ref) => {
    this.markerAddRef = ref;
  };

  MapMarkerRef = (ref) => {
    this.markerRef = ref;
  };

  changeInstructionVisibility = (instructionNumbersAndShow) => {
    const instructionVisibility = [...this.state.showInstructions];
    instructionNumbersAndShow.forEach((numbersAndVisibility) => {
      instructionVisibility[numbersAndVisibility[0]] = numbersAndVisibility[1];
      this.setInstructionSeen(numbersAndVisibility[0]);
    });
    this.setState({
      showInstructions: instructionVisibility,
    });
  };

  setInstructionSeen(index) {
    let storedInstructions = JSON.parse(localStorage.getItem('seens'));
    storedInstructions = storedInstructions ? storedInstructions : [false, false, false, false];
    storedInstructions[index] = true;
    localStorage.setItem('seens', JSON.stringify(storedInstructions));
  }

  render () {
    return (
      <div className="Map">
        {getParamValue('category') &&
        getParamValue('category').includes(ADDABLE_CATEGORY) ?
          <MapInstruction
            onClickCallback={this.changeInstructionVisibility}
            callbackValue={0}
            instructions={'Tryk her for at tilføje din historie'}
            showInstructions={this.state.showInstructions[0]}
            instructionClass={''}
            showCloseButton={true}
            seen={this.state.instructionsSeen[0]}
          /> : ''
        }

        <MapInstruction
          onClickCallback={this.changeInstructionVisibility}
          callbackValue={1}
          instructions={'Træk og slip boblen for at placere din historie'}
          showInstructions={this.state.showInstructions[1]}
          instructionClass={'2'}
          showCloseButton={true}
          seen={this.state.instructionsSeen[1]}
        />

        <div className="Map__container" ref={this.setMapRef}/>

        {getParamValue('category') &&
        getParamValue('category').includes(ADDABLE_CATEGORY) ?
          <MapOverlay
            onClickFormCallback={this.onClickAdd}
            onClickInfoCallback={this.onClickPopInfo}
          /> : ''
        }

        <div className="Map__menus">
          {getParamValue('category') &&
          getParamValue('category').includes(SERVICES) ?
            <div className="Map__categories">
              {orderCategoryList(this.state.usedTypes).map((value, key) =>
                value &&
                <a href="#" id={value.class} key={key} onClick={this.onClickChangeVisibility(value)} className="active">
                  <div className="Map__categories__text">{ ReactHtmlParser(value.name) }</div>
                  <CheckedBox/>
                  <UncheckedBox />
                </a>)}
            </div> : ''
          }

          {getParamValue('category') &&
          getParamValue('category').includes(ADDABLE_CATEGORY) ?
            <div className="Map__categories Map__categories__feedback">
              {this.state.usedCategories.map((value, key) =>
                value &&
                <a href="#" id={value.class} key={key} onClick={this.onClickChangeVisibility(value)} className="active">
                  <div className="Map__categories__text">{ ReactHtmlParser(value.name) }</div>
                  <CheckedBox/>
                  <UncheckedBox />
                </a>)}
            </div> : ''
          }

          {getParamValue('category') &&
            getParamValue('category').includes(PATHS) ?
            <MapPathMenu map={this.map} /> : ''
          }
        </div>

        <Modal
          isActive={this.state.showModal}
          onClickClose={this.onClickCloseModal}
        >
          <MapMarkerForm
            key={this.state.location}
            form={FORM_NAME_MAP_MARKER}
            location={this.state.location}
            onSubmitCallback={this.onSubmit}
            onClickCallback={this.onClickCloseModal}
          />

          <MapInstruction
            onClickCallback={this.changeInstructionVisibility}
            callbackValue={4}
            instructions={'Indtast din email og tlfnr hvis vi må kontakte dig'}
            showInstructions={this.state.showInstructions[2]}
            instructionClass={'3'}
            showCloseButton={false}
            seen={this.state.instructionsSeen[2]}
          />

          <MapInstruction
            onClickCallback={this.changeInstructionVisibility}
            callbackValue={4}
            instructions={'Hvad handler din historie om? Vælg kategori her'}
            showInstructions={this.state.showInstructions[3]}
            instructionClass={'4'}
            showCloseButton={false}
            seen={this.state.instructionsSeen[3]}
          />
        </Modal>

        <div className="Map__hidden">
          <MapMarker ref={this.MapMarkerRef}/>
          <MapMarkerAdd ref={this.setMarkerAddRef}/>
        </div>

        <div className="Map__logos">
          <div className="MapMarker__circle">
            <NordeaIcon/>
          </div>
          <div className="MapMarker__circle mx-2">
            <NpaIcon/>
          </div>
          <div className="MapMarker__circle">
            <RucIcon/>
          </div>
        </div>
      </div>
    );
  }
}

Map.propTypes = {
  places: PropTypes.shape({
    items: PropTypes.objectOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        image: PropTypes.string,
        category: PropTypes.string,
        type: PropTypes.string,
        userName: PropTypes.string,
        description: PropTypes.string,
      }),
    ),
  }),
};

const mapStateToProps = ({
  places,
}) => ({
  places,
});

export default compose(
  connect(mapStateToProps),
)(Map);
