export const INFO_TEXT_HTML = '<h1><strong>Velkommen!</strong></h1><br>' +
  '<br>' +
  '<h3>Vær med til at placere spændende historier ' +
  'om Naturpark Åmosen og omegn på dette ' +
  'kort. Det kan være historier fra vikingetiden, 2. ' +
  'verdenskrig, historier om naturen, ' +
  'arkitekturen, godserne eller lokale ' +
  'røverhistorier og minder fra barndommens gade. </h3> <br>' +
  '<br>' +
  '<h3>Vi glæder os til at læse dine historier og om dine oplevelser!</h3> <br>' +
  '<br>' +
  '<p>Kortet er en del af projekt "Nye Veje i Naturpark Åmosen" hvor vi indsamler dine historier og dine' +
   'oplevelser på kort. Fortællingerne formidles på kortet her, i foldere, på plakater og andet materiale fra' +
   'Naturpark Åmosen. Projektet er finansieret af Nordea Fonden og ledes af Roskilde Universitet. GDPR: Alle' +
   'historier og data behandles anonymt. Når du lægger en historie op, giver du samtykke til, at vi må' +
   'offentliggøre og videregive din historie i projektregi. Vi behandler dine oplysninger anonymt, og de' +
   'videregives ikke. Personoplysninger er alene for at kunne kontakte dig, hvis din historie er særlig' +
   'interessant. Ønsker du, at din historie skal slettes, eller ønsker du blot at kontakte os, kan du altid kontakte' +
   'Naturpark Åmosen. </p>';

export const ADD_MARKER_TEXT_HTML = 'Tak for din historie til borgerkortet. Når vi har indsamlet ' +
  'tilstrækkelig med historier om naturen, ' +
  'de særegne steder og værdierne i Naturpark Åmosen vil dine historier ' +
  'blive offentlig tilgængelig. Historierne vil findes på hjemmesiden ' +
  'til gavn for andre gæster og borgere. Alle historier skal dog først godkendes. ' +
  'Det er meningen, at der fra hjemmesiden kan generes foldere, ' +
  'kort og andet materiale som gæster i naturparken frit kan printe og tage med hjemmefra. ' +
  'Historierne kommer selvfølgelig også på naturparkens hjemmeside. ' +
  'På Roskilde Universitet og på vegne af projekt ”Nye Veje i Naturpark Åmosen” ' +
  'er vi også glade for din historie, og vi arbejder videre på vores Geopedia over Åmosen.';
