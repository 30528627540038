export const orderCategoryList = categories => {
  const orderedData = [];

  for (const category of categories) {
    // eslint-disable-next-line default-case
    switch (category.name) {
      case 'Overnatning':
        orderedData[0] = category;
        break;
      case 'Spisesteder':
        orderedData[1] = category;
        break;
      case 'Aktiviteter og Oplevelser':
        orderedData[2] = category;
        break;
      case 'Salg og Indkøb':
        orderedData[3] = category;
        break;
      case 'Faciliteter':
        orderedData[4] = category;
        break;
    }
  }

  return orderedData;
}