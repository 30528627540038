import axios from 'axios';

export const REDUX_PERSIST_KEY = 'root';
export const ENV_DEVELOPMENT = 'development';
export const MAPBOX_STYLE = 'mapbox://styles/gisgroupdk/ck9jn3oq90yrk1ip4vgk26gmv';
export const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ2lzZ3JvdXBkayIsImEiOiJjazdsbzNkcmQwOTVmM2VyMDVkaTdtbHY3In0.-JYa8qqRnV5jgF5x0wqJ8g';
export const AIR_TABLE_TOKEN = 'patSrRy0FJfB69dj7.79f3b75bea3d44bc0b463281c761c5dd4f39df076728113e1da48d3ab0411a5e'; // read only permissions
export const AIR_TABLE_URL = 'https://api.airtable.com/v0/appVZNAcMZblAbtGy';
export const AIR_TABLE_URL_PREFIX = '/Ydelser?view=Ydelser_adr';
export const AIR_TABLE_SERVICES_FIELDS = '&fields%5B%5D=Virksomheds+Navn&fields%5B%5D=Katagori&fields%5B%5D=Adresse&fields%5B%5D=Telefon+&fields%5B%5D=mail&fields%5B%5D=web&fields%5B%5D=Beskrivelse+af+virskomhed&fields%5B%5D=evt.+billede+af+virksomhed%2Fprodukt%2Foplevelse&fields%5B%5D=X-koor&fields%5B%5D=Y-koor&fields%5B%5D=Vises+på+kort';
export const API_DOMAIN = '/wp-json/map-app';
export const API_CATEGORIES = '/wp-json/categories';
export const DEFAULT_LOCATION = {
  lng: 11.346,
  lat: 55.587,
};

export const AIR_TABLE_AUTH = {
  headers: { Authorization: `Bearer ${AIR_TABLE_TOKEN}` }
};

export let CATEGORIES = [
  {
    value: 'kulturvaerdi',
    label: 'Kulturværdi',
  },
  {
    value: 'naturvaerdi',
    label: 'Naturværdi',
  },
  {
    value: 'service',
    label: 'Service',
  },
  {
    value: 'oplevelser',
    label: 'Oplevelser',
  }
];

export const fetchCategories = () => {
  axios.get(API_CATEGORIES + '/get')
    .then(response => {
      CATEGORIES = response.data.items;
    })
    .catch(function (error) {
      console.log('error', error);
    });
};

export const MAPBOX_MARKER_HEIGHT = 35;
export const MAPBOX_POPUP_OFFSETS = {
  'top': [0, MAPBOX_MARKER_HEIGHT],
  'bottom': [0, -MAPBOX_MARKER_HEIGHT],
};
