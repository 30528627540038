import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, formPropTypes } from 'redux-form';
import { bindActionCreators, compose } from 'redux';
import axios from 'axios';

// Styles.
import './MapMarkerForm.scss';

// Assets.
import { ReactComponent as LogoIcon } from '../../asstes/svg/marker-logo.svg';

// Components.
import InputFile from '../input/file/InputFile';
import InputText from '../input/InputText';
import InputSelect from '../input/InputSelect';
import InputTextArea from '../input/InputTextArea';
import MapMarkerFormAddSuccess from './MapMarkerFormAddSuccess';

// Utilities.
import validatorEmail from '../../utilities/form/validator/validatorEmail';
import validatorRequired from '../../utilities/form/validator/validatorRequired';
import validatorMaxLength from '../../utilities/form/validator/validatorMaxLength';

// Constants.
import {CATEGORIES, API_DOMAIN, fetchCategories} from '../../constants/Settings';

// Actions.
import placesAdd from '../../actions/places/placesAdd';
import validatorMinLength from '../../utilities/form/validator/validatorMinLength';
import normalizePhoneNumber from '../../utilities/form/normalizer/normalizerPhoneNumber';

const fieldsProps = {
  text: {
    validate: [validatorRequired],
  },
  description: {
    validate: [validatorRequired, validatorMaxLength(1200)],
  },
  userName: {
    validate: [validatorRequired],
  },
  userEmail: {
    validate: [validatorEmail],
  },
  userPhone: {
    validate: [validatorMinLength(8)],
  },
  category: {
    validate: [validatorRequired],
  }
};

class MapMarkerForm extends React.PureComponent {

  constructor(){
    super();
    fetchCategories();
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.handleSubmit(this.handleSubmit)();
  };

  handleSubmit = (values) => (
    this.readFile(values.image).then((image) => (
      this.props.actions.placesAdd({
        ...values,
        image,
        id: Date.now(),
        location: this.props.location,
        type: 'Feedback'
      }).then(this.handleResponseAction)
    ))
  );

  handleResponseAction = ({ payload }) => {
    this.props.onSubmitCallback(payload);
    
    this.handleMarkSubmit(payload);
  };

  handleMarkSubmit = (args) => {
    axios.post(API_DOMAIN + '/add', args)
      .catch(function (error) {
        console.log(error);
      });
  };

  readFile = (file) => (
    new Promise((resolve, reject) => {
      if (!file) {
        return resolve(undefined);
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ({ target: { result } }) => {
        resolve(result);
      };
      reader.onerror = (() => {
        resolve(undefined);
      });
    })
  );

  render = () => (
    <div className="MapMarkerForm">

      <div className="MapMarkerForm__header">
        <div className="MapMarkerForm__header--circle">

          {this.props.submitSucceeded ? (
            <LogoIcon/>
          ) : (
            <LogoIcon/>
          )}

        </div>
      </div>

      {this.props.submitSucceeded ? (
        <MapMarkerFormAddSuccess
          onClickCallback={this.props.onClickCallback}
        />
      ) : (
        <form className="MapMarkerForm__form" onSubmit={this.onSubmit}>

          <Field
            type="text"
            id="userName"
            name="userName"
            label="Navn"
            component={InputText}
            validate={fieldsProps.userName.validate}
          />

          <Field
            type="email"
            id="userEmail"
            name="userEmail"
            label="Din email (publiceres ikke)"
            component={InputText}
            validate={fieldsProps.userEmail.validate}
          />

          <Field
            type="tel"
            id="userPhone"
            name="userPhone"
            label="Dit telefonnummer (publiceres ikke)"
            component={InputText}
            validate={fieldsProps.userPhone.validate}
            normalize={normalizePhoneNumber}
          />

          <Field
            id="category"
            type="select"
            name="category"
            label="Kategori"
            placeholder=" "
            component={InputSelect}
            options={CATEGORIES}
            validate={fieldsProps.category.validate}
            onChange={this.handleChange}
          />

          <Field
            id="title"
            type="text"
            name="title"
            label="Titel"
            component={InputText}
            validate={fieldsProps.text.validate}
          />

          <Field
            type="textarea"
            id="description"
            name="description"
            label="Din historie"
            placeholder="Op til 1200 karakterer"
            component={InputTextArea}
            validate={fieldsProps.description.validate}
          />

          <Field
            id="source"
            type="text"
            name="source"
            label="Kilde"
            component={InputText}
          />

          <Field
            id="image"
            name="image"
            label="Tilføj billede"
            component={InputFile}
          />

          <button
            type="submit"
            disabled={this.props.submitting}
            className="MapMarkerForm__button"
          >
            Tilføj til kortet
          </button>

          <button
            type="button"
            onClick={this.props.onClickCallback}
            disabled={this.props.submitting}
            className="MapMarkerForm__button--cancel"
          >
            Annuller
          </button>

        </form>
      )}

    </div>
  );
}

MapMarkerForm.propTypes = {
  ...formPropTypes,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  onSubmitCallback: PropTypes.func.isRequired,
  onClickCallback: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    placesAdd: PropTypes.func.isRequired,
  }),
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    placesAdd,
  }, dispatch),
});

export default compose(
  reduxForm(),
  connect(null, mapDispatchToProps),
)(MapMarkerForm);
